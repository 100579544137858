import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedBlogsAndWhitepapers } from '../../../assets/data/ConsolidatedBlogsAndWhitepapers';
import { SectionTilesProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};

const Blogs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  const sectionHeader = {
    title: 'Whitepapers',
    paragraph: ''
  };

  const content = ConsolidatedBlogsAndWhitepapers.Whitepapers;

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {content.map((item, index) => (
              <div key={index} className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">— {item.title}</p>
                  </div>
                  <div className="testimonial-item-footer text-sm mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">
                      Premalatha Manimaran, Director, Hashind Solutions
                    </span>
                    <span className="testimonial-item-link mt-32">
                      <Link to={`/whitepapers/${item.href}`}>Read More</Link>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Blogs.propTypes = propTypes;
Blogs.defaultProps = defaultProps;

export default Blogs;
