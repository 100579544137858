import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../../elements/Image';

const FooterLogo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src="/assets/images/logo/logo-with-tagline.png"
            alt="hashind-logo"
            width={170}
            height={170}
          />
        </Link>
      </h1>
    </div>
  );
};

export default FooterLogo;
