import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedIndustries } from '../../../assets/data/ConsolidatedIndustries';
import { ConsolidatedSectionHeaders } from '../../../assets/data/ConsolidatedSectionHeaders';
import { SectionTilesProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';


const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const AllIndustries = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'industries features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap center-content', pushLeft && 'push-left');

  const sectionHeader = ConsolidatedSectionHeaders.Industries;

  const content = ConsolidatedIndustries;

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {content.map((item, index) => (
              <div
                key={index}
                className="tiles-item reveal-from-bottom"
                style={{
                  background: `url(${item.image}${item.href}.svg)`
                }}>
                <Link to={`/industries/${item.href}`}>
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-content">
                      <h6 className="mt-0 mb-8">{item.title}</h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

AllIndustries.propTypes = propTypes;
AllIndustries.defaultProps = defaultProps;

export default AllIndustries;
