import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import AboutSection from '../components/sections/about/About';
import TextAndImage from '../components/sections/about/TextAndImage';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';

const About = () => {
  return (
    <>
      <HeroWithBgImageAndDescription className="about" bannerContent={ConsolidatedBanner.About} />
      <Breadcrumb />
      <AboutSection />
      <TextAndImage />
    </>
  );
};

export default About;
