import React, { useEffect, useRef } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import LayoutDefault from './layouts/LayoutDefault';
import routes from './routes';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded');
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          {routes.map(({ path, name, Component }, key) => (
            <AppRoute exact path={path} key={key} component={Component} layout={LayoutDefault} />
          ))}
        </Switch>
      )}
    />
  );
};

export default App;
