import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FooterConnect from './partials/FooterConnect';
import FooterLogo from './partials/FooterLogo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

// import Logo from './partials/Logo';
const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div className={classNames('site-footer-inner', topDivider && 'has-top-divider')}>
          <FooterConnect />
          <div className="footer-top space-between text-sm">
            {/* <Logo /> */}
            <FooterLogo />
            <FooterSocial />
          </div>
          <div className="footer-bottom space-between text-xs invert-order-desktop">
            <FooterNav />
            <div className="footer-copyright">© 2022 Hashind Solutions. All Right Reserved.</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
