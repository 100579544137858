import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import GenericSection from '../components/sections/common/GenericSection';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';

const PrivacyPolicy = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="privacy-policy-banner"
        bannerContent={ConsolidatedBanner.PrivacyPolicy}
      />
      <Breadcrumb />
      <GenericSection className={'privacy-policy-content'}>
        <div className="container-mb">
          <div>
            <strong className="text-color-high">
              This Privacy Policy is effective from Oct 14th, 2022, since the date Hashind Solutions
              was incorporated, and duly note that the Policy will be updated regularly to
              incorporate changes in the governing rules globally and also in the way we handle your
              personal data.
            </strong>
            <p className="mt-8">
              This page will tell you everything you need to know about Hashind's policies around
              your personal data, how they are processed and controlled and also the rights you have
              from a Client/Visitor stand-point.
            </p>
          </div>

          <div>
            <strong className="text-color-high">1. Data from your physical presence:</strong>
            <p>
              i) We will take note of your name, phone number and address in our visitors log which
              we are not entitled to share or sell. We respect your privacy. However, for future
              prospects and company related activities or for emergency purposes we will utilise
              this information within the company. Our employees and our company understands that
              your data is highly sensitive and PII.
            </p>
            <p>
              ii) Hashind Solutions will be under CCTV surveillance 24x7. The motion pictures
              captured will not be used in any social platforms or for personal use.
            </p>
            <p>
              iii) The information from i) and ii), however, under legal circumstances, threat to
              the Company, threat to the surroundings, for police enquiry, theft, fire, immigration
              purposes, background checks, mandatory filings, record keeping, reporting obligations,
              in compliance with Government inspections, in compliance with public authorities,
              conducting investigations, complying with internal policies and procedures, privacy,
              safety and legal rights of Hashind, against fraud and for risk management purposed
              will be shared.
            </p>
          </div>

          <div>
            <strong className="text-color-high">
              2. Personal data from your visit to our website:
            </strong>
            <p>
              i) We can collect data from you providing your information for newsletter
              registrations, or by filling forms that enables you to contact us
            </p>
            <p>
              ii) We can also collect data using our underlying technology platforms from your visit
            </p>
            <p>
              iii) We can collect data from the surveys you fill in for us for research or feedback,
              and these surveys are completely in your discretion
            </p>
            <p>iv) Any posts or comments you might resgister for Hashind Solutions</p>
            <p>
              v) We will also collect data about your device, platform, channel, OS, IP, browser
              type purely for analytics and bot elimination. We analyse such information to
              understand what is best about our website and to understand the performance of our
              pages
            </p>
            <p>
              vi) We will also gather information details from your visit at an overall level which
              mean important KPIs like traffic, CTR, etc
            </p>
            <p>
              vii) Our site may contain third-party links, partner network links, advertisers who
              may process your personal information for their own purpose and we do not accept any
              responsibility and liability towars such third-party programs
            </p>
            <p>
              viii) Please see <Link to="/cookies-policy">cookies policy</Link> for more information
            </p>
          </div>

          <div>
            <strong className="text-color-high">
              3. Usage for Marketing and Business purposes:
            </strong>
            <p>
              i) We send emails to our customers and potential customers with whom we want to
              maintain the business relationship with or we want to develop a business opportunity
              with, in accordance with the laws. Please be aware of phishing emails and be vigilant
              from whom you are getting emails. Please note that Hashind Solutions will never misuse
              your data
            </p>
            <p>
              ii) Post your acknowledgment we might send you notifications to keep you updated on
              what is happening with the company you are interested in
            </p>
            <p>
              iii) Your responses to targeted emails, social media posts, website activity, contact
              data, press links and published articles are used for analytics and future business
              opportunities
            </p>
            <p>iv) You can always opt-out of emails and push notifications</p>
            <p>
              v) Please note that we will use your data for content creation solely for our business
              improvement purposes
            </p>
          </div>

          <div>
            For further information or if you feel violated, please feel free to{' '}
            <Link to="/contact-us">contact us.</Link>
          </div>
        </div>
      </GenericSection>
    </>
  );
};

export default PrivacyPolicy;
