import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import GenericSection from '../components/sections/common/GenericSection';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';

const CookieConsentAndPrivacyPreference = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="cookie-consent-and-privacy-preference-banner"
        bannerContent={ConsolidatedBanner.CookieConsentAndPrivacyPreference}
      />
      <Breadcrumb />
      <GenericSection className={'cookie-consent-and-privacy-preference-content'}>
        <div className="container-mb">
          <div>
            <table>
              <tr>
                <td>
                  <strong className="text-color-high">Your Privacy</strong>
                </td>
                <td>
                  Visiting any website, gives the leverage to store and retrieve personal
                  information through the use of cookies. This is mostly to make the website perform
                  for you as intended by you and does not directly identify you. We respect your
                  right to privacy and hence we allow you to choose what information you might like
                  to share with us. Click on the different sections to know more. Please be warned
                  that blocking certain cookies may affect the performance and impact the experience
                  and services offered.
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="text-color-high">Strictly Necessary Cookies</strong>
                </td>
                <td>
                  These cookies do not store any Personal Identifiable Information (PII). They are
                  used for proper functioning of the system. They are responses you provide while
                  interacting with the website. Turning them off will have a huge impact in the
                  website functions and the website may not work as intended in all places
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="text-color-high">Performance and Analytics Cookies</strong>
                </td>
                <td>
                  The information that these cookies collect are anonymous and aggregated to a
                  certain level always. They collect information related to pages' performance and
                  vital indicators that will tell us which page in the website need attention on
                  priority. These cookies help us in fixing bugs in the system or help us identify
                  pages that aren't working as intended
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="text-color-high">Personlization Cookies</strong>
                </td>
                <td>
                  These cookies help in tracking information like region, language and other
                  demographic details to provide more personalised and enhanced features. These
                  cookies might be tracked by some third-party vendors, but disabling them will
                  affect some services on the site
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="text-color-high">Ads and Social Media Cookies</strong>
                </td>
                <td>
                  These are targeting cookies set by our advertising partners. These cookies help in
                  building your profile of interests and show relevant advertisements. If you
                  disable this cookie, you will see less targeted ads
                </td>
              </tr>
            </table>
          </div>
        </div>
      </GenericSection>
    </>
  );
};

export default CookieConsentAndPrivacyPreference;
