import classNames from 'classnames';
import React from 'react';

import { ConsolidatedCommon } from '../assets/data/ConsolidatedCommon';
import Image from '../components/elements/Image';
import { SectionProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const Error = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'error hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const errorData = ConsolidatedCommon.Error;

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              {errorData[0].title}
            </h1>
            <div className="container-xs">
              <p className="m-0 reveal-from-bottom" data-reveal-delay="400">
                {errorData[0].descriptionShort}
              </p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800">
            <div>
              <Image
                className="has-shadow"
                src={errorData[0].image}
                alt={errorData[0].imageAlt}
                width={896}
                height={504}
              />
            </div>
            <div className="container-mb mt-32">
              <p className="m-0 mb-32 reveal-from-bottom text-sm" data-reveal-delay="400">
                {errorData[0].descriptionBrief}
              </p>
            </div>
            <div className="container-mb mt-32">
              <button>
                <a href={'/'}>{errorData[0].button}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Error.propTypes = propTypes;
Error.defaultProps = defaultProps;

export default Error;
