export const ConsolidatedSectionHeaders = {
  HomeService: {
    title: 'Services',
    paragraph:
      'Delivering a stellar customer experience is made possible only when we practice the core value of being customer-focused, customer-centric and customer-first. It is our philosophy to make customers feel appreciated and important in all circumstances. Click on each section below to know more about the smart services we offer at Hashind Solutions, keeping customers first, always!'
  },
  AboutHashind: {
    title: 'About Hashind',
    paragraph:
      'Hashind is a premier service-based startup from a humble small town called Dindigul, Tamil Nadu, India. Although the city is famous for Biryani and huge locks, we wanted to tap other skills and harness the multi-talented resources readily available, to create "Digital Dindigul". However, we do not want to stop there, but work towards building and growing into an employee-friendly and customer-centric workplace, in the near future. The solutions we engineer range from website design and development, web applications, mobile applications to SaaS platforms. We also have an in-house team that can generate dashboards for you. Partner with us, to understand more about the work we do!'
  },
  AboutHashindMotto: {
    title: 'Our Motto',
    tagline: 'Transcends Outstanding Work',
    paragraph:
      'The higher-order purpose of Hashind Solutions is to discover the seeds of greater potential for every business and enable the transformational growth of such seeds with an aspirational ability which is inherent in the DNA of our services. We foster a liberating and empowering mindset within the organization that pushes every one of them to become a leader and a greatest asset.'
  },
  Contact: {
    title: "Let's get in touch",
    paragraph:
      'Tired of reading through the exhaustive list of services we offer? We actually offer a lot more than what you see here.',
    weAreOpen: "We're open for any suggestion or just to have a chat"
  },
  Industries: {
    title: 'Industries',
    paragraph:
      'Having a strong industrial expertise and domain knowledge can never be over-emphasized. It enables one to take the right decisions. Hashind Solutions is always open to learning and knowing more about any industry as it gives us a competitive advantage and we always want to be reliable for our customers. Click on each from below to know more about the work we offer in each industry'
  },
  Services: {
    title: 'Services',
    paragraph:
      'Delivering a stellar customer experience is made possible only when we practice the core value of being customer-focused, customer-centric and customer-first. It is our philosophy to make customers feel appreciated and important in all circumstances. Click on each section below to know more about the smart services we offer at Hashind Solutions, keeping customers first, always!'
  }
};
