import classNames from 'classnames';
import React from 'react';

import { ConsolidatedSectionHeaders } from '../../../assets/data/ConsolidatedSectionHeaders';
import { SectionSplitProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const TextAndImage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'about our-motto features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = ConsolidatedSectionHeaders.AboutHashindMotto;

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item">
                <div className="text-base tt-u mb-8">{sectionHeader.title}</div>
                <h2 className="mt-0 mb-12 text-color-primary">{sectionHeader.tagline}</h2>
                <p className="m-0 text-sm">{sectionHeader.paragraph}</p>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={'/assets/images/about/our-motto.svg'}
                  alt="about-motto"
                  width={528}
                  height={200}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TextAndImage.propTypes = propTypes;
TextAndImage.defaultProps = defaultProps;

export default TextAndImage;
