export const ConsolidatedAboutHashindQA = [
  {
    question: 'Who are we?',
    answer:
      'In reality, either there is a problem to solve or an opportunity to take, and we are both problem solvers and opportunists!'
  },
  {
    question: 'What do we do?',
    answer:
      'We Hash real-world problems into nubs and provide innovative and sustainable solutions. We aim at not just solving problems at every opportunity but create a long-lasting impact and a realistic impression in the way we deal with them'
  },
  {
    question: 'What is our vision?',
    answer:
      'We want to build personalized applications for our customers, support them, manage with them and build a trusted relationship with them. We will always stay customer-focused and never compromise on the quality of products delvered!'
  },
  {
    question: 'What is our mission?',
    answer:
      'We will, most certainly, strive to build a digital and online world for our customers that will drive traction towards their businesses'
  }
];
