import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';
import ContactUsDetails from '../components/sections/contact/ContactUsDetails';

const Contact = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="contact"
        bannerContent={ConsolidatedBanner.Contact}
      />
      <Breadcrumb />
      <ContactUsDetails />
    </>
  );
};

export default Contact;
