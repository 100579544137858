import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';
import AllIndustries from '../components/sections/industries/AllIndustries';

const Industries = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="industries"
        bannerContent={ConsolidatedBanner.Industries}
      />
      <Breadcrumb />
      <AllIndustries />
      <div className="m-32 connect-us">
        Can't find what you are looking for? Email us at{' '}
        <a href="mailto:connect@hashind.com"> connect@hashind.com</a>
      </div>
    </>
  );
};

export default Industries;
