import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import GenericSection from '../components/sections/common/GenericSection';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';

const TermsAndConditions = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="terms-and-conditions-banner"
        bannerContent={ConsolidatedBanner.TermsAndConditions}
      />
      <Breadcrumb />
      <GenericSection className={'terms-and-conditions-content'}>
        <div className="container-mb text-sm">
          <div className="text-sm">
            <p>
              Welcome to Hashind Solutions (OPC) Private Limited ["Hashind" or "Us" or "Our" or "We"
              used below]. We hope you have a seamless experience in our site!
            </p>

            <p>
              <strong className="text-color-high">
                PLEASE READ THE BELOW TERMS AND CONDITIONS CAREFULLY BEFORE BROWSING THE WEBSITE.
                USING THIS WEBSITE WILL INDICATE THAT YOU HAVE READ AND ACCEPTED THE BELOW TERMS.
              </strong>
            </p>
          </div>

          <div>
            <p>
              Find below the terms and conditions we stand by. These terms of use are a legal
              contract between you (the visitor) and us (Hashind Solutions), without limitation or
              qualification.
            </p>
            <p>
              i) This legal contract talks about the governance policies that we would like for you
              to adhere to, over the content you see in this website not limited to pictures, text,
              software, information, materials, documents, whitepapers, blogs and any kind of
              service that is made available to you
            </p>
            <p>
              ii) Accessing this website means accessing the content that is made available in this
              site, hence it is your responsibility to provide accurate, true, complete and current
              information as long as you are on this site
            </p>
            <p>
              iii) If you feel any security information is breached in this website, you must notify
              us <Link to="/contact-us">contact us</Link> immediately
            </p>
            <p>
              iv) We collect information solely in relation with your access and behaviour in this
              website to enhance your experience. Please read more about the{' '}
              <Link to="/privacy-policy">privacy policy</Link> and{' '}
              <Link to="/cookies-policy">cookies policy</Link> to know more
            </p>
            <p>
              v) You are welcome to visit our website and know more about the work we do and the
              industries we work at, solely for your personal understanding or business purposes.
              You must not replicate, transmit, copy, edit, exploit, reproduce or alter any content
              from this website{' '}
            </p>
            <p>
              vi) You agree to take complete responsibility of all user submissions that you perform
              in the website, not limited to filling forms with personal information, survey
              information, etc., and any such user submissions are non-confidential and
              non-proprietary and Hashind Solutions has no control in all these user submissions
            </p>
            <p>
              vii) You agree to not infringe the copyright, trade secrets, trademark, privacy
              rights, moral codes and legal right
            </p>
            <p>
              viii) Your inputs and submissions does not include email addresses, addresses, phone
              numbers and names other than your own
            </p>
            <p>ix) Your inputs doesn't contain other illegal and content from other websites</p>
            <p>x) You agree to not input any viruses, spywares, worms, etc. </p>
            <p>
              xi) You agree to refrain from unethical, illegal, vulgar, hateful and harassing
              language
            </p>
            <p>
              xii) The information provided on this site is free of cost and only for informational
              purposes
            </p>
            <p>
              xiii) The site may contain links which are maintained by other sites and Hashind
              Solutions will not be held accountable
            </p>
            <p>
              xiv) Hashind Solutions is under no obligation to protect the confidentiality of your
              ideas or for recreating similar ideas or to compensate you when disclosed
            </p>
          </div>
          <div>
            <p>
              Hashind Solutions maintains this site from Dindigul, Tamil Nadu, India and by using
              this website you agree to the above terms and conditions.
            </p>
          </div>
        </div>
      </GenericSection>
    </>
  );
};

export default TermsAndConditions;
