export const ConsolidatedServices = [
  {
    title: 'Campaign and Marketing Analytics',
    description:
      'Analyzing campaign data for better ROI, identifying bot activities and effectively remove them from the dataset, improved and new gen marketing KPIs',
    image: '/assets/images/services-icon/campaign-black.png',
    imageAlt: 'campaign',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Dashboarding and Visualization',
    description:
      'Scheduling and automating periodically refreshed dashboards/ live and real-time dashboards/ near real-time dashboard creation',
    image: '/assets/images/services-icon/visualization-black.png',
    imageAlt: 'visualization',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Descriptive Analytics and Deep Dive',
    description: 'Investigate and mitigate issues with appropriate tools and analysis',
    image: '/assets/images/services-icon/descriptive-black.png',
    imageAlt: 'descriptive',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Diagnostic Analytics',
    description:
      'Drawing correlations, trends and relationships to understand why something has happened',
    image: '/assets/images/services-icon/diagnostic-black.png',
    imageAlt: 'diagnostic',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Digital Analytics and Reporting',
    description:
      'User activity analysis, customer retention, SEO, web usage metrics, user experience metrics, page performance and much more',
    image: '/assets/images/services-icon/analytics-reporting-black.png',
    imageAlt: 'analytics-reporting',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Ecommerce Analytics',
    description:
      'Tracking user behaviour in store pages and converting them into customers by creating a personalised shopping experience',
    image: '/assets/images/services-icon/ecommerce-black.png',
    imageAlt: 'ecommerce',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Predictive Analytics',
    description: 'What happens when and why',
    image: '/assets/images/services-icon/predictive-black.png',
    imageAlt: 'predictive',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Prescriptive Analytics',
    description: 'Guidance on what has to be done next',
    image: '/assets/images/services-icon/prescriptive-black.png',
    imageAlt: 'prescriptive',
    type: 'analytics-and-reporting'
  },
  {
    title: 'Retail Analytics',
    description: 'Analyzing supply chain information for better management',
    image: '/assets/images/services-icon/retail-black.png',
    imageAlt: 'retail',
    type: 'analytics-and-reporting'
  },
  {
    title: 'UI/UX Design',
    description:
      'Convenient, Contemporary and authentic designs that are eye-catching and steadfast',
    image: '/assets/images/services-icon/ui-ux-design-black.png',
    imageAlt: 'ui-ux-design',
    type: 'cutting-edge-designs'
  },
  {
    title: 'Development Consulting',
    description:
      'We work with you to understand the narrow down to the requirements of your software',
    image: '/assets/images/services-icon/development-consulting-black.png',
    imageAlt: 'development-consulting',
    type: 'development-and-modernization'
  },
  {
    title: 'Enterprise Website Development',
    description:
      'Unleash the full potential of building an extensive customer base with a professional corporate website',
    image: '/assets/images/services-icon/enterprise-web-black.png',
    imageAlt: 'enterprise-web',
    type: 'development-and-modernization'
  },
  {
    title: 'Mobile Application Development',
    description: 'Applications are not limited to devices',
    image: '/assets/images/services-icon/mobile-app-black.png',
    imageAlt: 'mobile-app',
    type: 'development-and-modernization'
  },
  {
    title: 'Modernization',
    description:
      'Updating legacy applications with newer and advanced tools, languages, platforms, frameworks and approaches',
    image: '/assets/images/services-icon/modernization-black.png',
    imageAlt: 'modernization',
    type: 'development-and-modernization'
  },
  {
    title: 'SaaS Development',
    description:
      'Hardware and software dependency free applications that address your specific needs',
    image: '/assets/images/services-icon/saas-development-black.png',
    imageAlt: 'saas-development',
    type: 'development-and-modernization'
  },
  {
    title: 'Web Application Development',
    description:
      'Address all needs and issues with an app that is custom made to suite the exact requirement',
    image: '/assets/images/services-icon/web-app-black.png',
    imageAlt: 'web-app',
    type: 'development-and-modernization'
  },
  {
    title: 'Minimum Viable Product Development',
    description: 'An MVP to kickstart your successful business',
    image: '/assets/images/services-icon/mvp-black.png',
    imageAlt: 'mvp',
    type: 'prototype-development'
  },
  {
    title: 'POCs',
    description: 'A Proof of your Concept for the business pitch',
    image: '/assets/images/services-icon/poc-black.png',
    imageAlt: 'poc',
    type: 'prototype-development'
  },
  {
    title: 'Testing, QC and QA',
    description:
      "Extensive QC practices at every phase, since we don't like to compromise on the quality of our deliverables",
    image: '/assets/images/services-icon/quality-assurance-black.png',
    imageAlt: 'quality-assurance',
    type: 'quality-assurance'
  }
];
