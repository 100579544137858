import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';

const Careers = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="careers banner-image"
        bannerContent={ConsolidatedBanner.Careers}
      />
      <Breadcrumb />
      <div className="careers get-in-touch container">
        <div className="col-md-5 mt-32 mb-32">
          <h3 className="text-color-primary">Why should you be a part of Hashind Solutions?</h3>
          <p className="col-md-5 mt-32">
            1. We are pretty serious about the{' '}
            <Link to="/code-of-values">Code of Business Ethics</Link>, which you should definitely
            read through, that will tell you more about us
          </p>
          <p>2. We are flexibile with the timings</p>
          <p>3. We have a very good office setup that you will definitely like!</p>
          <p>4. You can work from wherever you want to as long as work gets done</p>
          <p>5. You can directly interact with the clients and improve your soft skills</p>
          <p>
            6. Get Professional experience by interacting with people who have more than 10 years of
            experience in the industry
          </p>
          <p>
            7. Identify your passion and be free to go behind it with your whole heart. Love what
            you do and live for what you love and in Hashind Solutions, you can definitely follow
            your heart!
          </p>
        </div>
        <div className="col-md-5 mt-32 mb-32">
          <p>
            If you are interested in working for us, if you think you have good skills and if you
            know that you are simply interesting, write to us at{' '}
            <a href="mailto:careers@hashind.com">careers@hashind.com</a> or{' '}
            <a href="mailto:workwithus@hashind.com">workwithus@hashind.com</a>, with your
            résumé/CV/Biodata and a cover letter that tells us who you are in brief!
          </p>
        </div>
        <div className="col-md-5 mt-32 mb-32 text-xs">
          *Alert: Stay safe from false job alerts, fraudulent calls and phishing emails. Hashind
          Solutions does not reach out to you asking for a registration fee or any money, to provide
          jobs!
        </div>
      </div>
    </>
  );
};

export default Careers;
