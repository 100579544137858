import { Link } from 'react-router-dom';

const BreadcrumbItem = (props) => {
  return (
    <span className={props.className}>
      <Link className={props.className} to={props.link}>
        {props.value}
      </Link>
    </span>
  );
};

export default BreadcrumbItem;
