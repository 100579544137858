export const ConsolidatedContact = [
  {
    title: 'Address',
    description: '63, Thirunagar, GTN Salai, BK Pudur PO, Dindigul Tamil Nadu India 624 005',
    image: '/assets/images/contact/address-black.png',
    imageAlt: 'address'
  },
  {
    title: 'Phone',
    description: '+91 89252 29225',
    image: '/assets/images/contact/phone-black.png',
    imageAlt: 'phone'
  },
  {
    title: 'Email',
    description: 'info@hashind.com',
    image: '/assets/images/contact/email-black.png',
    imageAlt: 'email'
  }
];
