import About from './views/About';
import Careers from './views/Careers';
import CodeOfValues from './views/CodeOfValues';
import Contact from './views/Contact';
import CookieConsentAndPrivacyPreference from './views/CookieConsentAndPrivacyPreference';
import CookiesPolicy from './views/CookiesPolicy';
import Home from './views/Home';
import Industries from './views/Industries';
import IndustryDetails from './views/IndustryDetails';
import List from './views/List';
import NotFound from './views/NotFound';
import PageDetails from './views/PageDetails';
import PrivacyPolicy from './views/PrivacyPolicy';
import Services from './views/Services';
import ServicesHighLevelDetails from './views/ServicesHighLevelDetails';
import TermsAndConditions from './views/TermsAndConditions';
import WhitePaper from './views/WhitePapers';

export default [
  { path: '/', name: 'Home', Component: Home },
  { path: '/about', name: 'About', Component: About },
  { path: '/industries', name: 'Industries', Component: Industries },
  { path: '/industries/:industryName', name: 'Industry Details', Component: IndustryDetails },
  { path: '/services', name: 'Services', Component: Services },
  {
    path: '/services/:highLevelServicesName',
    name: 'Services Details',
    Component: ServicesHighLevelDetails
  },
  { path: '/careers', name: 'Careers', Component: Careers },
  { path: '/contact-us', name: 'Contact', Component: Contact },
  { path: '/pages', name: 'Pages', Component: List },
  { path: '/pages/:pageName', name: 'Page Details', Component: PageDetails },
  { path: '/whitepapers', name: 'Whitepapers', Component: List },
  { path: '/whitepapers/:pageTitle', name: 'Whitepaper Details', Component: WhitePaper },
  { path: '/code-of-values', name: 'Code Of Values', Component: CodeOfValues },
  { path: '/terms-and-conditions', name: 'Terms and Conditions', Component: TermsAndConditions },
  { path: '/privacy-policy', name: 'Privacy Policy', Component: PrivacyPolicy },
  { path: '/cookies-policy', name: 'Cookies Policy', Component: CookiesPolicy },
  {
    path: '/cookie-consent-and-privacy-preference',
    name: 'Cookie Consent And Privacy Preference',
    Component: CookieConsentAndPrivacyPreference
  },
  { path: '*', name: 'Not Found', Component: NotFound }
];
