import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';
import HighLevelServices from '../components/sections/services/HighLevelServices';

const Services = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="services"
        bannerContent={ConsolidatedBanner.Services}
      />
      <Breadcrumb />
      <HighLevelServices className="services" />{' '}
      <div className="m-32 connect-us">
        Can't find what you are looking for? Email us at{' '}
        <a href="mailto:connect@hashind.com"> connect@hashind.com</a>
      </div>
    </>
  );
};

export default Services;
