import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import routes from '../../../routes';
import BreadcrumbItem from './BreadcrumbItem';

const Breadcrumb = (props) => {
  const params = useParams();
  const location = useLocation();
  const matchPath = location.pathname;
  const breadcrumbs = routes;
  let breadcrumbData = [];
  const crumbs = breadcrumbs
    .map(({ path, ...rest }) => ({
      path: Object.keys(params).length
        ? Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            path
          )
        : path,
      ...rest
    }))
    .filter(({ path }) => matchPath.includes(path));

  crumbs.map(({ name, path }) => {
    breadcrumbData.push({ link: path, value: name, active: matchPath === path });
  });

  return (
    <section className={`${props.className} container`}>
      <div className="breadcrumb">
        {breadcrumbData.map((ele) => {
          return (
            <BreadcrumbItem
              key={ele.value}
              link={ele.link}
              value={ele.value}
              className={`breadcrumb-item${ele.active ? ' active' : ''}`}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Breadcrumb;
