import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { SectionProps } from '../../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">Do you want to create a sustainable future?</h3>
          </div>
          <div className="cta-action">
            <p className="no-highlight">
              You either want to{' '}
              <Link to="/contact-us">talk about your business and collaboratively ideate</Link> or{' '}
              <Link to="/careers">work with us to create viable solutions</Link>.
            </p>
            <p>
              For the former, call us at <a href="tel:+91 8925 229 225">+91 8925 229 225</a> or
              write to us at <a href="mailto:info@hashind.com">info@hashind.com</a>
            </p>
            <p>
              For the latter, write to us with your resume and cover letter to{' '}
              <a href="mailto:careers@hashind.com">careers@hashind.com</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
