import classNames from 'classnames';
import React from 'react';

import { ConsolidatedBlogsAndWhitepapers } from '../../../assets/data/ConsolidatedBlogsAndWhitepapers';
import { SectionProps } from '../../../utils/SectionProps';
import GenericSection from './GenericSection';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const WhitePaperSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  title,
  ...props
}) => {
  const outerClasses = classNames(
    'whitepapers section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const whitepaperData = ConsolidatedBlogsAndWhitepapers.Whitepapers.filter((whitepaperEach) => {
    return whitepaperEach.href === title;
  });

  return (
    <>
      <section {...props} className={outerClasses}>
        <div className="container-lg">
          <div className={innerClasses}>
            <GenericSection className="whitepapers-content">
              <div className="container-mb">
                <h2 className="mt-0 mb-32">{whitepaperData[0].title}</h2>
                <div className="mt-16 mb-16">
                  <div dangerouslySetInnerHTML={{ __html: whitepaperData[0].descriptionLong }} />
                  <div className="item-footer text-sm mt-32 mb-0">
                    <div className="item-name text-color-high">--</div>
                    <div className="item-name text-color-high">Premalatha Manimaran</div>
                    <div className="item-designation text-color-high">Director</div>
                    <div className="item-company text-color-high">Hashind Solutions</div>
                  </div>
                </div>
              </div>
            </GenericSection>
          </div>
        </div>
      </section>
    </>
  );
};

WhitePaperSection.propTypes = propTypes;
WhitePaperSection.defaultProps = defaultProps;

export default WhitePaperSection;
