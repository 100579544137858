export const ConsolidatedHighLevelServices = [
  {
    title: 'Analytics and Reporting',
    href: 'analytics-and-reporting',
    description:
      'Reliant on data across different horizons to analyze and deduce inferences and insights by using various computational powers and techniques',
    image: '/assets/images/banner/services/analytics-and-reporting.svg',
    iconImage: '/assets/images/services-icon/common/analytics-reporting-black.png',
    imageAlt: 'analytics-and-reporting',
    button: ''
  },
  {
    title: 'Cutting-Edge Designs',
    href: 'cutting-edge-designs',
    description: 'Original, yet modern designs which are user-friendly and convenient',
    image: '/assets/images/banner/services/cutting-edge-designs.svg',
    iconImage: '/assets/images/services-icon/common/cutting-edge-designs-black.png',
    imageAlt: 'cutting-edge-designs',
    button: ''
  },
  {
    title: 'Development and Modernization',
    href: 'development-and-modernization',
    description:
      'Build, update, rebuild, replatform, imagine and reimagine the endless possibilities of development competencies across different devices, platforms and specific requirements',
    image: '/assets/images/banner/services/development-and-modernization.svg',
    iconImage: '/assets/images/services-icon/common/development-modernization-black.png',
    imageAlt: 'development-and-modernization',
    button: ''
  },
  {
    title: 'Prototype Development',
    href: 'prototype-development',
    description: 'With our business acumen we help promote your business ideas',
    image: '/assets/images/banner/services/prototype-development.svg',
    iconImage: '/assets/images/services-icon/common/prototype-development-black.png',
    imageAlt: 'prototype-development',
    button: ''
  },
  {
    title: 'Quality Assurance',
    href: 'quality-assurance',
    description:
      'Ensuring completeness, integrity and totality of the service and products using systemic and precise methods',
    image: '/assets/images/banner/services/quality-assurance.svg',
    iconImage: '/assets/images/services-icon/common/quality-assurance-black.png',
    imageAlt: 'quality-assurance',
    button: ''
  }
];
