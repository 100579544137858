import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Blogs from '../components/sections/common/Blog';
import CodeOfValuesSection from '../components/sections/common/CodeOfValuesSection';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';
import HighLevelServices from '../components/sections/services/HighLevelServices';

const Home = () => {
  return (
    <>
      <HeroWithBgImageAndDescription className="home" bannerContent={ConsolidatedBanner.Home} />
      <HighLevelServices className="home illustration-section-01" />
      <CodeOfValuesSection className="home" />
      <Blogs className="home whitepapers" />
    </>
  );
};

export default Home;
