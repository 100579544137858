import React from 'react';

import CodeOfValuesSection from '../components/sections/common/CodeOfValuesSection';

const CodeOfValues = () => {
  return (
    <>
      <CodeOfValuesSection className="code-of-values" />
    </>
  );
};

export default CodeOfValues;
