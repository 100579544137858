import './assets/scss/style.scss';

import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'react-router-dom';
import ScrollToTopButton from 'react-scroll-to-top';

import App from './App';
import ScrollToTop from './utils/ScrollToTop';
import Error from './views/Error';

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));

const errorHandler = (error, errorInfo) => {
  console.log('Logging ', error, errorInfo);
};

root.render(
  <React.StrictMode>
    <Router history={history}>
      <ScrollToTop />
      <ScrollToTopButton smooth color="#FAA819" />
      <ErrorBoundary FallbackComponent={Error} onError={errorHandler}>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>
);
