import React from 'react';

import Breadcrumb from '../components/sections/common/Breadcrumb';
import IndustryDetails from '../components/sections/industries/IndustryDetails';

const IndustryDetailsPage = ({ match, location }) => {
  const {
    params: { industryName }
  } = match;
  return (
    <>
      <Breadcrumb className="industry-details" />
      <IndustryDetails type={industryName} />
      <div className="m-32 connect-us">
        Can't find what you are looking for? Email us at{' '}
        <a href="mailto:connect@hashind.com"> connect@hashind.com</a>
      </div>
    </>
  );
};

export default IndustryDetailsPage;
