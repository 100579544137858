import classNames from 'classnames';
import React from 'react';

import { ConsolidatedIndustries } from '../../../assets/data/ConsolidatedIndustries';
import { SectionProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const IndustryDetailsSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  type,
  ...props
}) => {
  const outerClasses = classNames(
    'industry-details hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const industryDetailsData = ConsolidatedIndustries.filter((industry) => {
    return industry.href === type;
  });

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              {industryDetailsData[0].title}
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                {industryDetailsData[0].descriptionShort}
              </p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800">
            <div>
              <Image
                className="has-shadow"
                src={`${industryDetailsData[0].image}${industryDetailsData[0].href}.svg`}
                alt={industryDetailsData[0].imageAlt}
                width={896}
                height={504}
              />
            </div>
            <div className="container-mb mt-32">
              <p className="m-0 mb-32 reveal-from-bottom text-sm" data-reveal-delay="400">
                {industryDetailsData[0].descriptionBrief}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

IndustryDetailsSection.propTypes = propTypes;
IndustryDetailsSection.defaultProps = defaultProps;

export default IndustryDetailsSection;
