import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import GenericSection from '../components/sections/common/GenericSection';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';

const CookiesPolicy = () => {
  return (
    <>
      <HeroWithBgImageAndDescription
        className="cookies-policy-banner"
        bannerContent={ConsolidatedBanner.CookiesPolicy}
      />
      <Breadcrumb />
      <GenericSection className={'cookies-policy-content'}>
        <div className="container-mb">
          <div>
            <p>
              <strong className="text-color-high">
                This Cookies Policy is effective from Oct 14th, 2022, since the date Hashind
                Solutions was incorporated, and duly note that the Policy will be updated regularly
                to incorporate changes in the governing rules globally and also in the way we handle
                your personal data.
              </strong>
            </p>

            <p>
              Cookies are tiny files with little information downloaded to your devices that you
              access the website from. These tiny bits of information help to identify you on every
              visit and perform specific operations that will enhance your experience. First-party
              cookies are managed by us and third-party cookies are managed by third-party
              companies, ad agencies, vendors, analytics providers, etc., who give us intelligence
              about our website and help us track the performance of our pages, also to give you
              target ads and services. They collect information on your interaction with the
              website.
            </p>

            <p>
              Cookies help in remembering your wants and preferences. They aid in improving your
              experience with the website. They can also personalise the ads you see on the website
              which are more relevant and of interest to you. In turn they also help us to analyze
              your preferences and helps us improve our suggestions for you.
            </p>

            <p>
              If you want to control what is being tracked using cookies, you can read more about
              the different type of cookies that you can enable in the{' '}
              <Link to="/cookie-consent-and-privacy-preference">cookie consent console</Link>.
            </p>

            <p>
              If you feel violated or if you think your privacy is compromised, please feel free to{' '}
              <Link to="/contact-us">contact us</Link>.
            </p>
          </div>
        </div>
      </GenericSection>
    </>
  );
};

export default CookiesPolicy;
