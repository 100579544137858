export const ConsolidatedBanner = {
  Home: [
    {
      title: 'Because we believe in Kaizen',
      description:
        'Transcending outstanding work made possible by adopting continuous improvement methods.',
      descriptionShort:
        'As an organisation, we strongly believe that reducing waste, balancing work and avoiding burden ...',
      descriptionBrief:
        'As an organisation, we strongly believe that reducing waste, balancing work and avoiding burden is the only way to identify gaps to bridge, figuring problems to solve and knowing scopes for continuous improvement. That said, we have unanimously embraced Muda, Mura and Muri techniques in the work we do, striving, at the end of the day, to develop sustainable solutions for our customers. How, do you ask? we believe in writing optimized codes, developing reusable assets, being agile always - not just in work, using state-of-the-art governance and delivery methods, etc.',
      button: 'Read More',
      image: '/assets/images/banner/home/kaizen.svg',
      imageAlt: 'because-we-believe-in-kaizen',
      href: 'because-we-believe-in-kaizen'
    },
    {
      title: 'Business Consulting and Intelligence',
      description: "Ignorance can be bliss, but isn't knowlegde power?",
      descriptionShort:
        'Communication is key, yes, but the intelligent systems that we build for you are data driven, which ...',
      descriptionBrief:
        'Communication is key, yes, but the intelligent systems that we build for you are data driven, which are more than words. We help our customers ideate and solutionize with us, to develop personalized yet powerful systems that address their need. We can forecast what is in store, we have the capabilities to deep dive and not just be diagnostic but predicive, prescriptive and descriptive. We aim to not just develop but remain outstanding at it. Talk to us, we will work with you to make your business complete. Call us!',
      button: 'Discover',
      image: '/assets/images/banner/home/business-consulting.svg',
      imageAlt: 'business-consulting-and-intelligence',
      href: 'business-consulting-and-intelligence'
    },
    {
      title: 'Are we Reactive? Proactive? Predictive?',
      description: 'We are a rare mix of all.',
      descriptionShort:
        'In the beginning days of our relationship with our customers, it might seem to look like we are reactive in general, but ...',
      descriptionBrief:
        'In the beginning days of our relationship with our customers, it might seem to look like we are reactive in general, but you must know that we are collecting vital data that nurtures proactive and predictive capabilities. The only thought we strive to maintain as a constant is to support our customers beyond the realm of imagination, just to make that extra little effort to accomplish as intended',
      button: 'Explore',
      image: '/assets/images/banner/home/proactive.svg',
      imageAlt: 'are-we-reactive-proactive-predictive',
      href: 'are-we-reactive-proactive-predictive'
    }
  ],
  About: [
    {
      title: 'About',
      description: '',
      button: '',
      image: '/assets/images/banner/about/about.svg',
      imageAlt: 'about'
    }
  ],
  Contact: [
    {
      title: 'Contact',
      description: '',
      button: '',
      image: '/assets/images/banner/contact/contact.svg',
      imageAlt: 'contact'
    }
  ],
  CoreValues: [
    {
      title: 'Code Of Values',
      description: '',
      button: '',
      image: '/assets/images/banner/core-values/core-values.svg',
      imageAlt: 'core-values'
    }
  ],
  Careers: [
    {
      title: 'Careers',
      description: '',
      button: '',
      image: '/assets/images/banner/careers/careers.svg',
      imageAlt: 'careers'
    }
  ],
  Industries: [
    {
      title: 'Industries',
      description: '',
      button: '',
      image: '/assets/images/banner/industries/industries.svg',
      imageAlt: 'industries'
    }
  ],
  Services: [
    {
      title: 'Services',
      description: '',
      button: '',
      image: '/assets/images/banner/services/services.svg',
      imageAlt: 'services'
    }
  ],
  TermsAndConditions: [
    {
      title: 'Terms And Conditions',
      description: 'Effective date: October 14, 2022',
      button: '',
      image: '/assets/images/banner/terms-and-conditions/terms-and-conditions.svg',
      imageAlt: 'terms-and-conditions'
    }
  ],
  PrivacyPolicy: [
    {
      title: 'Privacy Policy',
      description: 'Last Updated: November 9, 2022',
      button: '',
      image: '/assets/images/banner/privacy-policy/privacy-policy.svg',
      imageAlt: 'privacy-policy'
    }
  ],
  CookiesPolicy: [
    {
      title: 'Cookies Policy',
      description: 'Last Updated: November 9, 2022',
      button: '',
      image: '/assets/images/banner/cookies-policy/cookies-policy.svg',
      imageAlt: 'cookies-policy'
    }
  ],
  CookieConsentAndPrivacyPreference: [
    {
      title: 'Cookie Consent And Privacy Preference',
      description: 'Last Updated: November 9, 2022',
      button: '',
      image: '/assets/images/banner/cookies-policy/cookie-consent-and-privacy-preference.svg',
      imageAlt: 'cookie-consent-and-privacy-preference'
    }
  ],
  Blogs: [
    {
      title: 'Blogs',
      description: '',
      button: '',
      image: '/assets/images/banner/blog/blog.svg',
      imageAlt: 'blogs'
    }
  ],
  Whitepapers: [
    {
      title: 'Whitepapers',
      description: '',
      button: '',
      image: '/assets/images/banner/white-paper/white-paper.svg',
      imageAlt: 'whitepapers'
    }
  ],
  Pages: [
    {
      title: 'Pages',
      description: '',
      button: '',
      image: '/assets/images/banner/page/page.svg',
      imageAlt: 'pages'
    }
  ]
};
