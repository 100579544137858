import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ConsolidatedBanner } from '../../../assets/data/ConsolidatedBanner';
import { ConsolidatedBlogsAndWhitepapers } from '../../../assets/data/ConsolidatedBlogsAndWhitepapers';
import { SectionTilesProps } from '../../../utils/SectionProps';

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const ListItem = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  type,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('ta-l', pushLeft && 'push-left');

  let content, path;

  if (type === 'Pages') {
    content = ConsolidatedBanner.Home;
    path = 'pages';
  } else if (type === 'Blogs') {
    content = ConsolidatedBlogsAndWhitepapers.Blogs;
    path = 'blogs';
  } else {
    content = ConsolidatedBlogsAndWhitepapers.Whitepapers;
    path = 'whitepapers';
  }

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            {content.map((item, index) => (
              <div key={index} className="tiles-item reveal-from-bottom has-bottom-divider">
                <Link to={`/${path}/${item.href}`}>
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-content">
                      <h6 className="mt-0 mb-8">{item.title}</h6>
                      <p className="m-0 text-sm">{item.descriptionShort}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
