export const ConsolidatedIndustries = [
  {
    title: 'Automotives',
    descriptionShort: 'Rapid and continuous innovation to drive digital transformation',
    descriptionBrief:
      "The future of mobility is indeed in the fastest gear now than ever. And the reason being, we want to embrace technology which supports rapid and continuous improvements in the industry, putting us in the race, while reshaping the industry itself to accomodate sustainable and environment friendly options. Have we realised that we have, in reality, shifted from hardware-driven automotives to software-driven automotives? That we are already stepping into the next realm of advancements in this industry? Well, software has slowly creeped in and taken over the majority, for good, and has already started making us comfortable with all those advances. We are eager to keep this topic going, why don't you get in touch with us?",
    href: 'automotives',
    image: '/assets/images/banner/industries/',
    imageAlt: 'automotives'
  },
  {
    title: 'Community Services',
    descriptionShort: 'Facilitating giving back a little to the society',
    descriptionBrief:
      'There are a lot of businesses that help good causes and helping them is one of our key focus too, because it creates a positive impact in the society that we live in, it helps the place to grow economically, promotes newer businesses and ideas and most importantly, it keeps us human enough. Hashind Solutions is very fond of improving the community too and makes it happen in all ways possible',
    href: 'community-services',
    image: '/assets/images/banner/industries/',
    imageAlt: 'community-services'
  },
  {
    title: 'Consumer Packaged Goods',
    descriptionShort:
      'Fast moving consumer goods/ CPG have the most challenges that demands extensive mindfulness',
    descriptionBrief:
      "Consumer goods which need frequent replacements and replenishments and with shorter life span, generally tend to become a part of a person's life and routine. Most consumers don't like constant changes but love an update. These goods are never off the market and not having the right strategy might kick them off the market. Hashind Solutions takes maximum interest to explore all the various complex algorithms that enhance these CPG's growth at an accelerating pace",
    href: 'consumer-packaged-goods',
    image: '/assets/images/banner/industries/',
    imageAlt: 'consumer-packaged-goods'
  },
  {
    title: 'Consumer Services',
    descriptionShort:
      'Empowering business that cater to consumers and providing an intelligent platform for their services',
    descriptionBrief:
      'Consumption is the sole end and purpose of all production. Said that, the consumer and their requirements exceeds all attention. Hashind Solutions works with such customers to meticulously build solutions to address their wants',
    href: 'consumer-services',
    image: '/assets/images/banner/industries/',
    imageAlt: 'consumer-services'
  },
  {
    title: 'Digital and Online Marketing',
    descriptionShort: 'Everything is becoming Digital, and so should Marketing',
    descriptionBrief:
      'Gone are the days when marketers used traditional marketing methods to capture leads and targets. Also, Digital Marketing is not limited to campaigns and online banners, and has grown exponentially in the recent few years, especially after the pandemic of 2018, which created a brand new state - New Normal. We have harnessed the unending and numerous possibilities that the online world could offer. We have created a routine that will include working smartly from anywhere, be it on a vacation too. Problems need right solutions at the right time. This Digital space is going to grow and Marketing methods are also getting personalized, the way consumers prefer and like. We, at Hashind Solutions, can assure you, that we are getting upgraded every nano second and are in the race.',
    href: 'digital-and-online-marketing',
    image: '/assets/images/banner/industries/',
    imageAlt: 'digital-and-online-marketing'
  },
  {
    title: 'eCommerce',
    descriptionShort: 'Creating an online ecospace',
    descriptionBrief:
      'How often do we go to the marketplace to buy something? Even services can be called for. There are so many policies in place that has made purchasing online very simple yet effective. Hashind solutions strives to help businesses in estabilishing a place in the online world for ease of all activities for both buyers and sellers, using apt platforms that ensures destruction-free and smooth transactions',
    href: 'ecommerce',
    image: '/assets/images/banner/industries/',
    imageAlt: 'ecommerce'
  },
  {
    title: 'Education and Technology',
    descriptionShort:
      'Education is the only way from darkness to light, and Technology promises to make it brighter',
    descriptionBrief:
      'Adapting technological advancements in Education has a very progressive impact, especially at tempestuous times when even schooling was remote and online. Hashind solutions understands the need of technology in teaching and other forms of education, also helps them in implementing innumerable recommendations for the future generations ',
    href: 'education-and-technology',
    image: '/assets/images/banner/industries/',
    imageAlt: 'education-and-technology'
  },
  {
    title: 'Finance and Investment',
    descriptionShort:
      'Money makes many things, but do we know if it makes the right and wise things?',
    descriptionBrief:
      'Banking and financial institutions are classified high-risk, which essentially means they expect 24 x 7 support. How do we provide them constant support without a break? Modern problems require Modern solutions and Hashind Solutions has puzzled out the exact requirement. Talk to us to know more.',
    href: 'finance-and-investment',
    image: '/assets/images/banner/industries/',
    imageAlt: 'finance-and-investment'
  },
  {
    title: 'Food and Beverage',
    descriptionShort:
      'F&B being the largest segment of Hospitality, requires us to create the exact experience customers wish for',
    descriptionBrief:
      'Basic cutlery that a customer has to be provided with, being more than 32 varieties, the services that this industry can offer can and must have a lot of varieties, and should also please the customer. Food is the most satisfying commodity and can also cause a quick detrimental effect for our business if the outcome is distasteful. Gathering customer feedback, providing compensations, offers and much more to just ice on the badly done cake is expensive. What if we foresee them and avoid overhead costs? Talk to us for more innovative solutions',
    href: 'food-and-beverage',
    image: '/assets/images/banner/industries/',
    imageAlt: 'food-and-beverage'
  },
  {
    title: 'Hi-Tech',
    descriptionShort: 'Superior and Cutting-edge technologies for a better tomorrow',
    descriptionBrief:
      'Constantly and frequently changing customer needs require faster solutions. Being quick is plausible by endorsing new, complex and transformational technologies that forbides reinventing the wheel at a much faster rate. We, at Hashind Solutions, believe in expanding boundaries, being aware of current trends and integrating fast-paced technologies. ',
    href: 'hi-tech',
    image: '/assets/images/banner/industries/',
    imageAlt: 'hi-tech'
  },
  {
    title: 'Human Resources and Payroll',
    descriptionShort: 'Managing human resources is almost a special skill ',
    descriptionBrief:
      'Taking into account payroll trends and avant-garde management strategies that simplifies company operations is highly imperative. Hashind Solutions help in HRM, performance management, recruitment, system integration, forming compliance and regulatory policies by providing appropriate platforms, designed to target specific wants. ',
    href: 'human-resources-and-payroll',
    image: '/assets/images/banner/industries/',
    imageAlt: 'human-resources-and-payroll'
  },
  {
    title: 'Informtation Technology',
    descriptionShort:
      'Suggesting apt devices and softwares for functioning of any business is prime',
    descriptionBrief:
      'Different businesses need different solutions, similarly, different solutions need different platforms, channels and devices to accomplish their true needs. On those lines, the new normal has opened new doors to beyond imaginations. Many companies foster smart working cultures, hybrid models, etc., and the exact necessity for each resource differ considerably. Very close to individuals, businesses and their stakeholders also need an altered setup which passively forces a change in how and where work is done. Hashind solutions also acts as a consultant in this regard, to identify fitting systems for the growing changes. ',
    href: 'informtation-technology',
    image: '/assets/images/banner/industries/',
    imageAlt: 'informtation-technology'
  },
  {
    title: 'Legal',
    descriptionShort: 'Increasing efficiency, credibility and performance with Legal Tech',
    descriptionBrief:
      'While digitizing legal data and maintaining the humungous load of legal documents is the major portion of legal management, there are much more high-tech tools and ideas that can be leveraged in the Legal industry. The advancements provide transparency between different key participants and faster processing and time management. Hashind Solutions has advanced capabilities to address the issues in this industry quickly.',
    href: 'legal',
    image: '/assets/images/banner/industries/',
    imageAlt: 'legal'
  },
  {
    title: 'Logistics and Supply Chain',
    descriptionShort:
      'Without supply chain there is no commerce, no economics, no jobs, no quality of life and no life',
    descriptionBrief:
      'Supply chain management and the visibility to every stakeholder in the process is a tedious job, and with the abundant opprtunities with technology, we will be able to build a strong and buoyant system that captures every little detail. At Hashind Solutions, we consider supply chain management as a vital discipline.',
    href: 'logistics-and-supply-chain',
    image: '/assets/images/banner/industries/',
    imageAlt: 'logistics-and-supply-chain'
  },
  {
    title: 'Pharmaceuticals',
    descriptionShort: 'Assisting Pharma companies to go further and faster',
    descriptionBrief:
      'Automation, computer-aided tasks, R&D, use of NLP, digital marketing, data analytics, deep learning and so much more, the opportunities for Technology in Pharma and the scope for Pharma to utilise Technology for a digital transformation is endless and unfathomable. Hashind Solutions take pride in involving in such life-saving golden opportunities. ',
    href: 'pharmaceuticals',
    image: '/assets/images/banner/industries/',
    imageAlt: 'pharmaceuticals'
  },
  {
    title: 'Real Estate',
    descriptionShort: 'PropTech solutions are undoubtedly the future for Real estate businesses',
    descriptionBrief:
      "The most resilient industry of all, that weaves the fabric of every country's economy, Real Estate business, has a certain set of repititive tasks that can very well be automated. Starting with just that, it has grown into a massive and undefeatedly the best of all, PropTech. Starting from a Realtor to Apprasier to seller to buyer and many more hands involved in the housing industry, Technology has proved itself to drive efficiency and integrity. Hashind Solutions is extremely delighted to indulge in this innovative approach of binding consumers and real estate professionals towards properties.",
    href: 'real-estate',
    image: '/assets/images/banner/industries/',
    imageAlt: 'real-estate'
  },
  {
    title: 'Retail',
    descriptionShort:
      "Growing your retail concept in an online world is challenging, but we love challenges, don't we!",
    descriptionBrief:
      'Although the incredible potential of online commerce is dominant, scaling up a retail experience is achievable in many ways. Offering a seamless shopping experience and in-store exciting experiences, is where Hashind Solutions would make a huge impact. Guiding stores to use affinity analysis and other data mining concepts to effectively create a foolproof store and unimaginable yet personalised experience for the customer would be ideal for most businesses',
    href: 'retail',
    image: '/assets/images/banner/industries/',
    imageAlt: 'retail'
  },
  {
    title: 'Travel and Hospitality',
    descriptionShort:
      'Personalising and customizing user experiences with the vast data available and use of smart technologies, will be your USP',
    descriptionBrief:
      "One major industry that can improve with feedback, build trusted relationships with the customers quickly, relies on customer's leisure time and built on customer's disposable income is Hospitality. The use of Technology, albeit can create a positive outlook for the business, one small err can make us topple over. It is threatening as it sounds. For these and many reasons, partnering with Hashind Solutions can help your business proactively identify disruptions and handle them ahead of time",
    href: 'travel-and-hospitality',
    image: '/assets/images/banner/industries/',
    imageAlt: 'travel-and-hospitality'
  }
];
