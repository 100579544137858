import classNames from 'classnames';
import React from 'react';

import { ConsolidatedContact } from '../../../assets/data/ConsolidatedContact';
import { ConsolidatedSectionHeaders } from '../../../assets/data/ConsolidatedSectionHeaders';
import { SectionSplitProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import SectionHeader from '../partials/SectionHeader';

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const ContactUsDetails = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'contact features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = ConsolidatedSectionHeaders.Contact;

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item">
                <div className="col-lg-4 col-md-5">
                  <div className="info-wrap p-4">
                    <div className="dbox">{sectionHeader.weAreOpen}</div>
                    <div className="dbox">
                      <div className="icon">
                        <Image
                          src={ConsolidatedContact[0].image}
                          alt={ConsolidatedContact[0].imageAlt}
                          width={64}
                          height={64}
                        />
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>{ConsolidatedContact[0].title}: </span>{' '}
                          <span>{ConsolidatedContact[0].description}</span>
                        </p>
                      </div>
                    </div>
                    <div className="dbox">
                      <div className="icon">
                        <Image
                          src={ConsolidatedContact[1].image}
                          alt={ConsolidatedContact[1].imageAlt}
                          width={64}
                          height={64}
                        />
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>{ConsolidatedContact[1].title}: </span>
                          <a href="tel://+91 89252 29225">{ConsolidatedContact[1].description}</a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox">
                      <div className="icon">
                        <Image
                          src={ConsolidatedContact[2].image}
                          alt={ConsolidatedContact[2].imageAlt}
                          width={64}
                          height={64}
                        />
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>{ConsolidatedContact[2].title}: </span>
                          <a href="mailto:info@hashind.com">{ConsolidatedContact[2].description}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/* <a
                  href="https://www.google.com/maps/@10.3676496,77.9902819,19.79z"
                  target={'_blank'}>
                  <Image
                    src={require('../../../assets/images/sample-google-map.png')}
                    alt="map"
                    width={528}
                    height={396}
                  />
                </a> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.677105507518!2d77.98835691523956!3d10.36768186943889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00ab5536c48b23%3A0x6db8180c7d04fc53!2sHashind%20Solutions%20(OPC)%20Private%20Limited!5e0!3m2!1sen!2sin!4v1669129023534!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactUsDetails.propTypes = propTypes;
ContactUsDetails.defaultProps = defaultProps;

export default ContactUsDetails;
