import React from 'react';

import Breadcrumb from '../components/sections/common/Breadcrumb';
import HomePageBannerDetails from '../components/sections/home/HomePageBannerDetails';

const PageDetails = ({ match, location }) => {
  const {
    params: { pageName }
  } = match;
  return (
    <>
      <Breadcrumb className="pages" />
      <HomePageBannerDetails name={pageName} />
    </>
  );
};

export default PageDetails;
