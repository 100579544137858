import React from 'react';

import Breadcrumb from '../components/sections/common/Breadcrumb';
import WhitePaperSection from '../components/sections/common/WhitePaperSection';

const WhitePaper = ({ match, location }) => {
  const {
    params: { pageTitle }
  } = match;
  return (
    <>
      <Breadcrumb className="whitepapers" />
      <WhitePaperSection title={pageTitle} />
    </>
  );
};

export default WhitePaper;
