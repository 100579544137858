import classNames from 'classnames';
import React from 'react';
import Slider from 'react-animated-slider';
import horizontalCss from 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';
import { SectionProps } from '../../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const HeroWithBgImageAndDescription = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  bannerContent,
  ...props
}) => {
  const outerClasses = classNames(
    'hero-with-bgimage-description hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const content = bannerContent;

  return (
    <section {...props} className={outerClasses}>
      <div className="container-lg">
        <div className={innerClasses}>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Slider classNames={horizontalCss}>
              {content.map((item, index) => (
                <div
                  key={index}
                  className="slider-content"
                  style={{
                    background: `url(${item.image})`
                  }}
                >
                  <div className="inner">
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                    <button>
                      <Link to={`/pages/${item.href}`}>{item.button}</Link>
                    </button>
                  </div>
                  {item?.userProfile && item?.user ? (
                    <section>
                      <img src={item.userProfile} alt={item.user} />
                      <span>
                        Posted by <strong>{item.user}</strong>
                      </span>
                    </section>
                  ) : (
                    ''
                  )}{' '}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroWithBgImageAndDescription.propTypes = propTypes;
HeroWithBgImageAndDescription.defaultProps = defaultProps;

export default HeroWithBgImageAndDescription;
