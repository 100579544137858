export const ConsolidatedCommon = {
  NotFound: [
    {
      title: '',
      descriptionShort: "We can't seem to find the page you're looking for.",
      descriptionBrief: '',
      button: 'Go To Homepage',
      image: '/assets/images/common/not-found.svg',
      imageAlt: 'not-found',
      href: 'not-found'
    }
  ],
  Error: [
    {
      title: '',
      descriptionShort: "Something's wrong here...",
      descriptionBrief: '',
      button: 'Go To Homepage',
      image: '/assets/images/common/error.svg',
      imageAlt: 'error',
      href: 'error'
    }
  ]
};
