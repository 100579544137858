import React from 'react';

import { ConsolidatedHighLevelServices } from '../assets/data/ConsolidatedHighLevelServices';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';
import HighLevelServicesDetails from '../components/sections/services/HighLevelServicesDetails';

const ServicesHighLevelDetails = ({ match, location }) => {
  const {
    params: { highLevelServicesName }
  } = match;

  const highLevelServicesBannerData = ConsolidatedHighLevelServices.filter(
    (highLevelServiceEach) => {
      return highLevelServiceEach.href === match.params.highLevelServicesName;
    }
  );

  return (
    <>
      <HeroWithBgImageAndDescription
        className="high-level-services-details"
        bannerContent={highLevelServicesBannerData}
      />
      <Breadcrumb />
      <HighLevelServicesDetails
        className={'high-level-services-details'}
        type={highLevelServicesName}
      />
      <div className="m-32 connect-us">
        Can't find what you are looking for? Email us at{' '}
        <a href="mailto:connect@hashind.com"> connect@hashind.com</a>
      </div>
    </>
  );
};

export default ServicesHighLevelDetails;
