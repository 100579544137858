import React from 'react';

import { ConsolidatedBanner } from '../assets/data/ConsolidatedBanner';
import Breadcrumb from '../components/sections/common/Breadcrumb';
import HeroWithBgImageAndDescription from '../components/sections/common/HeroWithBgImageAndDescription';
import ListItem from '../components/sections/common/ListItem';

const List = ({ match, location }) => {
  let urlParam = match.path;
  urlParam = urlParam.replaceAll('/', '');
  urlParam = urlParam[0].toUpperCase() + urlParam.substring(1, urlParam.length).toLowerCase();

  const bannerData = ConsolidatedBanner[urlParam];

  return (
    <>
      <HeroWithBgImageAndDescription className="list-view" bannerContent={bannerData} />
      <Breadcrumb />
      <ListItem className={'list-view'} type={urlParam} />
    </>
  );
};

export default List;
