import classNames from 'classnames';
import React from 'react';

import { ConsolidatedBanner } from '../../../assets/data/ConsolidatedBanner';
import { SectionProps } from '../../../utils/SectionProps';
import GenericSection from './GenericSection';
import HeroWithBgImageAndDescription from './HeroWithBgImageAndDescription';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const CodeOfValuesSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'code-of-values hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
      <section {...props} className={outerClasses}>
        <div className="container-lg">
          <div className={innerClasses}>
            <HeroWithBgImageAndDescription
              className="code-of-values-banner"
              bannerContent={ConsolidatedBanner.CoreValues}
            />
            <GenericSection className="code-of-values-content">
              <div className="container-mb center-content">
                <p className="mt-16 mb-16">
                  At Hashind Solutions, we strive to make a difference, we are well-coordinated with
                  a set of values and bound by a constituition and social code. We believe in doing
                  the right thing, and in the right way. We oblige by a commitment to provide beyond
                  the best for our customers and to never compromise on the quality of the work we
                  do. We hold ourselves responsible at every standpoint. We believe in
                  self-realization, while being agile by taking continuous feedback from peers and
                  managers. For us, integrity and rectitude surpasses everything, because we have
                  built a way of trust from our Clients. We stand strong, together, with an
                  unshakable foundation to fight, come what may!
                </p>
                <h2 className="mt-32 mb-32">WE LIVE OUR CODE OF VALUES BY…</h2>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            Self-<h4 className="text-color-primary">H</h4>elp
                          </strong>
                        </td>
                        <td>
                          Self-reflection is the only way to acceptance and growth. Make sure to
                          track your development and learn to measure your success.
                        </td>
                      </tr>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            Be <h4 className="text-color-primary">A</h4>ccountable
                          </strong>
                        </td>
                        <td>
                          You are accountable for the mistakes you make and the decisions you take.
                          Being a leader has the ability to inspire. Leaders are facilitative,
                          collaborative, have a vision and a direction. Be that leader!
                        </td>
                      </tr>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            <h4 className="text-color-primary">S</h4>ecure Quality
                          </strong>
                        </td>
                        <td>
                          Never compromise on the quality of our deliverables. Always QC before
                          production. This helps retain the trust from our customers.
                        </td>
                      </tr>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            Don't Hesitate To Take <h4 className="text-color-primary">H</h4>elp From
                            Others
                          </strong>
                        </td>
                        <td>
                          Feedback, in general are supportive. We are too close to ourselves and we
                          always need another pair of eyes to direct us to the right path
                        </td>
                      </tr>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            <h4 className="text-color-primary">I</h4>ntegrity Above Anything
                          </strong>
                        </td>
                        <td>
                          Conduct towards your work, respecting your peers and other employees,
                          being fair and ethical, complying with company laws, protecting employer
                          properties, being a good corporate citizen and always doing the right
                          thing in the right way
                        </td>
                      </tr>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            <h4 className="text-color-primary">N</h4>ecessary Preparations
                          </strong>
                        </td>
                        <td>
                          It's not wrong to be fully-prepared. Be ready to embrace change, take
                          risks, lose, win, be reponsible - be anything, but ready!
                        </td>
                      </tr>
                      <tr>
                        <td className="cov-heading">
                          <strong className="text-color-high">
                            Continuous <h4 className="text-color-primary">D</h4>evelopment
                          </strong>
                        </td>
                        <td>
                          Never cease to learn new techniques, tools and skills. Be ready to unlearn
                          and relearn if needed.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </GenericSection>
          </div>
        </div>
      </section>
    </>
  );
};

CodeOfValuesSection.propTypes = propTypes;
CodeOfValuesSection.defaultProps = defaultProps;

export default CodeOfValuesSection;
